// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/icon-search.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".unset-image[data-v-23fc74fa]{max-width:unset;height:unset}.menu-mobile[data-v-23fc74fa]{z-index:10}.mask[data-v-23fc74fa]{z-index:9}.mask[data-v-23fc74fa],.mask div[data-v-23fc74fa]{opacity:.6}button i.search[data-v-23fc74fa]{display:block;width:18px;height:18px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:contain;background-repeat:no-repeat;margin:0 auto}#header.fixed[data-v-23fc74fa]{position:fixed;z-index:100;width:100%;top:0;left:0;transition:.15s}@media only screen and (min-width:1281px) and (max-width:1366px){#dish_logo[data-v-23fc74fa]{padding-left:10}}@media only screen and (min-width:992px) and (max-width:1024px){#dish_logo[data-v-23fc74fa]{padding-left:0}}@media screen and (max-width:480px){#dish_logo[data-v-23fc74fa]{max-height:30px}}", ""]);
// Exports
module.exports = exports;
