<template>

  <div class="home" :style="mobileBodyHeight"> 
    <Loading :active="loading"
      :can-cancel="true"
      :loader="'dots'"
      :color="'#D6212E'"
      :height="130"
      :width="130"
      :opacity="'0.3'"
      :is-full-page="true" 
      > </Loading>
    <div class="p-5">
    <component
        v-for="(component,i) in componentData"
        :key="i"
        :is="component.name"
        :data="component.data"
      />
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import Iframe from '@/components/Iframe.vue'
import CarouselHeroBanner from '@/components/carousel/hero-banner.vue'
import CarouselPromotions from '@/components/carousel/promotions-banner.vue'
import CarouselDishPackages from '@/components/carousel/product-packages.vue'
import CarouselPromotionsBanner from '@/components/carousel/promotions-slim.vue'
import TitleComponent from '@/components/Title.vue'
import Loading from 'vue-loading-overlay';
import { ref } from '@vue/reactivity'

export default {
    name: "PreviewModule",
    components: {
        Loading,
        Iframe,
        CarouselPromotions,
        CarouselHeroBanner,
        CarouselDishPackages,
        CarouselPromotionsBanner,
        TitleComponent,
    },
    setup() {
        const route = useRoute() 
        const store = useStore()
        const componentData = ref([])
        const generateComponent = async ( base64 )  =>{
                const data =  await store.dispatch( 'generateComponent', base64 )
                componentData.value = data
        }
        const getPreviewPages = async ( id ) => {
              const exist = await store.dispatch('callPreviewPages', id  )
              if( exist ){
              const base64 = JSON.parse(atob(store.state.Pages.preview))
              generateComponent( base64 )
              }
        }

        console.log( route )
        if( route.query){
            if ( route.query.id){
                getPreviewPages(route.query.id )
            }
        }

        return { componentData }
    },
}
</script>