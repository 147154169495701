<template>
    <div class="p-3 page_registro _registro xs:p-6 sm:p-6">
        <div v-html="tagImage"> </div>
        <RegistroLoader v-if="loading" />
        <template v-else>
            <a href="#" class="underline text-red" v-if="activeStep != 1" @click.prevent="onValidate( activeStep - 1) "> Regresar </a>
            <h3 class="uppercase text-2xl">Registro</h3>
            <div class="separator_form"></div>
            <div class="step" v-show="activeStep == 1">
                <p class="font-semibold text-18 mb-4 xs:flex sm:flex xs:flex-col sm:flex-col xs:text-center sm:text-center">¿Cuál es tu nombre completo? </p>
                <Form v-slot="{ errors }" @submit="onSaveUserInformation" ref="stepInformation">
                    <div class="my-5">
                        <label for="lastName"> Apellido Paterno* </label>
                        <Field name="lastName"  pattern="^[a-zA-Z]+$" v-on:keypress="isLetter" type="text" :rules="isRequiredLettersOnly" aria-placeholder="Pérez" v-model="lastName" placeholder="Pérez"/>
                        <span class="text-red">{{ errors.lastName }}</span>
                    </div>
                    <div class="my-5">
                        <label for="middleName" class="font-medium" > Apellido Materno* </label>
                        <Field name="middleName" pattern="^[a-zA-Z]+$" v-on:keypress="isLetter" type="text"  :rules="isRequiredLettersOnly" aria-placeholder="Martínez" placeholder="Martínez" v-model="middleName"  />
                        <span class="text-red">{{ errors.middleName }}</span>
                    </div>
                    <div class="my-5">
                        <label for="firstName" class="font-medium" > Nombre(s)* </label>
                        <Field name="firstName" pattern="^[a-zA-Z]+$"  v-on:keypress="isLetter" type="text" :rules="isRequiredLettersOnly" aria-placeholder="Martínez" placeholder="Juan Jesus" v-model="firstName"/>
                        <span class="text-red">{{ errors.firstName }}</span>
                    </div>
                    <div class="font-medium">*Campos Obligatorios</div>
                    <button class="btn-red mt-5 xs:invisible sm:invisible xs:h-0 sm:h-0 xs:mt-0 sm:mt-0 xs:p-0 sm:p-0 " type="submit" > Siguiente</button>
                </Form>
            </div>
            <div class="step" v-show="activeStep == 2">
                <p class="font-semibold text-18 mb-4 xs:flex sm:flex xs:flex-col sm:flex-col xs:text-center sm:text-center">¿Cuáles son tus datos de contacto? </p>
                <Form v-slot="{ errors }" @submit="onSendSMS" ref="stepSendSMS">
                    <div class="my-5">
                        <label for="telefono" class="font-medium" > Celular* (10 dígitos) </label>
                        <Field name="telefono"  v-maska="'#### - #### - ##'" type="text" :rules="isRequiredTenDigits" :disabled="(isCodeInvalid ? true : false )" v-model="telefono_primary" aria-placeholder="000 - 000 - 00" placeholder="0000 - 0000 - 00" class="text-black" />
                        <span  class="text-red">{{ errors.telefono }}</span>
                        <div class="flex flex-wrap items-center xs:justify-center sm:justify-center xs:flex-col sm:flex-col" v-show="!isCodeInvalid" >
                            <button type="submit" class="btn-red my-2  mr-5 xs:h-0 sm:h-0 xs:mt-0 sm:mt-0 xs:p-0 sm:p-0 w-5/12 xs:w-7/12 sm:w-7/12 btn_enviar xs:mr-0 sm:mr-0 xs:hidden sm:hidden md:visible lg:visible xl:visible 2xl:visible"  @click="onSendSMS"> Enviar Código</button>
                            <button type="submit" class="btn-red-border my-2 mr-5 w-5/12 xs:w-7/12 sm:w-7/12 btn_enviar xs:mr-0 sm:mr-0 xs:visible sm:visible md:hidden lg:hidden xl:hidden 2xl:hidden"  @click="onSendSMS"> Enviar Código</button>
                            <p class="flex-1 xs:text-center sm:text-center" v-show="hasSMSCode">
                                ¿No recibiste el código?<br>
                                <button type="submit" href="#" class="font-semibold underline xs:invisible sm:invisible " >Reenvío de código </button>
                            </p>
                        </div>
                    </div> 
                </Form>
                <Form v-slot="{ errors }" class="mt-3" v-show="hasSMSCode && isCodeInvalid == null" @submit="onValidateSMSCode" ref="stepValidateCode">
                    <div class="my-5">
                        <div>
                            <label for="cell" class="font-medium w-full"  > Código de Validación* </label>
                        </div>
                        <div class="flex items-center">
                            <div class="w-4/12">
                                <Field name="code" v-model="smsCode" v-maska="'XXXXXX'" type="text" :rules="isRequiredMaxSix" aria-placeholder="" placeholder=""  />
                            </div>
                            <div class="w-6/12 p-5">
                            <button class="text-black font-semibold underline w-full text-left btn_validar" type="submit">Validar Código</button>
                            </div>
                        </div> 
                        <span class="text-red">{{ errors.code }}</span>                   
                    </div>
                </Form>
                <Form v-slot="{ errors }" class="mt-3" v-show="hasSMSCode && isCodeInvalid" @submit="onSaveContact" ref="stepSaveContact">
                    <!-- <div class="my-5">
                        <div>
                            <label for="telefonoSecundario" class="font-medium w-full"  > Teléfono secundario (opcional) </label>
                        </div>
                        <Field name="telefonoSecundario"  v-maska="'#### - #### - ##'" type="text" v-model="telefono_secondary" aria-placeholder="0000 - 0000 - 00" placeholder="0000 - 0000- 00"/>
                        <span>{{ errors.telefonoSecundario }}</span>
                    </div> -->
                    <div class="my-5">
                        <div>
                            <label for="email" class="font-medium w-full"  > Correo electrónico* </label>
                        </div>
                        <Field name="email" type="text" :rules="isRequiredEmail"  v-model="email"  aria-placeholder="me@mail.com" placeholder="me@mail.com"/>
                        <span class="text-red">{{ errors.email }}</span>
                        <a class="mt-4 block text-sm" @click="isShowInvalid = true">*Campos Obligatorios</a>
                    </div>
                    <div class="my-5">
                        <button class="btn-red xs:invisible sm:invisible" type="submit"> Siguiente</button>
                    </div>
                </Form>
            </div>
            <div class="step" v-show="isCustomerValid && activeStep == 3">
            <!-- <div class="step" > -->
                <p class="font-semibold text-18 mb-4 xs:flex sm:flex xs:flex-col sm:flex-col xs:text-center sm:text-center"> ¿Cuál es tu dirección? </p>
                <Form v-slot="{ errors }" @submit="onSubmitToPago" ref="stepAddress">
                    <div class="my-5 flex xs:flex-wrap sm:flex-wrap xs:justify-between sm:justify-between md:flex-nowrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap">
                        <div class="w-3/12 mr-4 xs:w-5/12 sm:w-5/12" >
                            <label for="postalCode" class="font-medium"> C.P.*</label>
                            <Field name="postalCode" v-model="geo_address.postal_code" :disabled="geo_disabled.postal_code" :rules="isRequired"  @blur="onSearchPostalCode" v-maska="'#####'" type="number" aria-placeholder="'#####'" placeholder="#####"/>
                            <span class="text-red">{{ errors.postalCode }}</span>
                        </div> 
                        <div class="w-3/12 mr-4 xs:w-6/12 sm:w-6/12 xs:mr-0 mt-7 sm:mr-0 flex xs:visible sm:visible md:hidden lg:hidden xl:hidden 2xl:hidden flex-col justify-center" v-if="stepCheckCoverage">
                            <span class="text-red underline" @click.prevent="onEditAddress"> Cambiar de dirección </span>
                        </div>
                        <div class="w-9/12 xs:w-full sm:w-full">
                            <label for="calle" class="font-medium"> Calle*</label>
                            <Field name="calle" type="text" :rules="isRequired" :disabled="geo_disabled.address"   aria-placeholder="Av México" placeholder="Av México" v-model="geo_address.calle"  />
                            <span class="text-red">{{ errors.calle }}</span>
                        </div>
                    </div>
                    <div class="my-5 flex xs:flex-wrap xs:justify-between sm:flex-wrap sm:justify-between">
                        <div class="w-3/12 mr-4 xs:w-5/12 sm:w-5/12 xs:mr-0 sm:mr-0">
                            <label for="exterior" class="font-medium"># Exterior*</label>
                            <Field name="exterior" :rules="isRequired"  type="text" aria-placeholder="Exterior" placeholder="Exterior" v-model="geo_address.exterior" />
                            <span class="text-red">{{ errors.exterior }}</span>
                        </div>
                        <div class="w-3/12 mr-4 xs:w-5/12 xs:mr-0 sm:w-5/12 sm:mr-0 ">
                            <label for="interior" class="font-medium"># Interior</label>
                            <Field name="interior" type="text"   v-model="geo_address.interior" aria-placeholder="Interior" placeholder="Interior"/>
                            <span class="text-red">{{ errors.interior }}</span>
                        </div>
                        <div class="w-6/12 xs:w-full sm:w-full">
                            <label for="colonia" class="font-medium"> Colonia*</label>
                            <Field name="colonia"
                                type="text"
                                :rules="isRequired"
                                :disabled="geo_disabled.colonia"
                                v-model="geo_address.colonia"
                                aria-placeholder="Colonia" placeholder="Colonia"
                                v-show="coloniaList.length == 0 "
                            />
                            <Field 
                                name="colonia"
                                as="select"
                                :rules="isRequired"
                                v-model="geo_address.colonia"
                                aria-placeholder="Colonia"
                                :disabled="geo_disabled.colonia"
                                placeholder="Colonia"  v-show="coloniaList.length != 0 ">
                                    <option value="" selected> Escoge una colonia </option> 
                                    <option v-for="(item, index) in coloniaList" :key="index" :value="item.ccolonia+'-'+item.colonia " > {{item.colonia }}</option> 
                            </Field>
                            <span class="text-red"> {{ errors.colonia }}</span>
                        </div>
                    </div>
                    <div class="my-5 flex xs:flex-col sm:flex-col">
                        <div class="w-6/12 mr-4 xs:w-full xs:mr-0 sm:w-full sm:mr-0">
                            <label for="municipio" class="font-medium"> Alcaldía o Municipio*</label>
                            <Field name="municipio" type="text" v-model="geo_address.municipio" :disabled="geo_disabled.municipio" :rules="isRequired" aria-placeholder="Municipio" placeholder="Municipio"/>
                            <span class="text-red">{{ errors.municipio }}</span>
                        </div>
                    
                        <div class="w-6/12 mr-4 xs:w-full xs:mr-0 sm:w-full sm:mr-0">
                            <label for="ciudad" class="font-medium"> Ciudad o estado*</label>
                            <Field name="ciudad" type="text"  v-model="geo_address.state" :disabled="geo_disabled.state"  :rules="isRequired" aria-placeholder="Ciudad o estado" placeholder="Ciudad o estado"/>
                            <span class="text-red">{{ errors.ciudad }}</span>
                        </div>
                    </div>
                    <div class="my-5 flex">
                        <div class="w-full flex flex-col">
                            <label for="reference" class="font-medium"> Referencias*</label>
                            <Field name="reference"  :rules="isRequired" v-slot="{ field }" > 
                                <textarea class="bg-gray-450 rounded-3xl h-32 p-4 my-2 pl-5" v-model="geo_address.reference"   aria-placeholder="Referenciaso"  placeholder="Ej. Entre la calle Reforma y Chapultepec"  v-bind="field"></textarea>
                            </Field>
                            <span class="text-red">{{ errors.reference }}</span>
                            <div class="text-sm mt-3 xs:text-center sm:text-center" > *Campos Obligatorios </div>
                        </div>
                    </div>
                    <div class="my-5 pt-5 pb-2 xs:invisible sm:invisible xs:h-0 sm:h-0">
                        <div class="w-full flex xs:flex xs:flex-col sm:flex sm:flex-col justify-between">
                            <button class="btn-border btn-md mb-4 w-6/12 xs:w-8/12 sm:w-8/12 xs:m-auto sm:m-auto" @click.prevent="onEditAddress"> Cambiar de dirección </button>
                            <button class="btn-red btn-md mb-4 w-6/12 xs:w-8/12 sm:w-8/12 xs:m-auto sm:m-auto" type="submit"> Continuar con el Pago</button>
                        </div>
                        <div class="flex w-full">
                             <p class="pt-3 text-sm  xs:text-center sm:text-center xs:mt-4 sm:mt-4"> Al continuar aceptas nuestro <a href="https://secciones.dish.com.mx/avisos-de-privacidad.html" target="_blank" class="font-bold underline"> Aviso de Privacidad </a> </p>
                        </div>
                    </div>
                </Form>
            </div>

        <div class="xs:visible sm:visible invisible fixed bottom-0 left-0 bg-white w-full ">
            <div class="bottom-nav flex items-center p-5 justify-center step-mobile ">
                <button class="btn-black mr-4" @click.prevent="onShowDetails()" v-if="activeStep == 1 || activeStep == 2 || activeStep == 3"> Ver detalles </button>
                <button class="btn-red" @click.prevent="onSubmtiMobile" v-if="activeStep == 1 || activeStep == 3 || (hasSMSCode && isCodeInvalid )"> Siguiente</button>
            </div>
            <p class="text-xs text-center underline mb-5" v-if="isCustomerValid">Al continuar aceptas nuestro <a href="https://secciones.dish.com.mx/avisos-de-privacidad.html" target="_blank" class="font-bold underline"> Aviso de Privacidad </a></p>
        </div> 

        </template>
        <transition name="fade"> 
            <ModalNotification v-show="isShow" @onClose="onClose"/>
        </transition>
        <transition name="fade"> 
            <ModalNotificationInvalid v-show="isCodeInvalid == false " @onClose="onCloseInvalid"/>
        </transition>
        <transition name="fade"> 
            <ModalMap v-if="isShowMap" @onClose="onCloseMap" :productData="selected"/>
        </transition>
         <transition name="fade"> 
            <ModalNotificationInvalidPostalCode v-show="isPostalCodeInvalid" :message="error" @onClose="onCloseSubscriptionError"/>
        </transition>
    </div>
</template>
<script>
import { maska } from 'maska'
import {Form, Field } from 'vee-validate'
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import ModalNotification from '@/components/modals/ModalNotification.vue'
import ModalNotificationInvalid from '@/components/modals/ModalNotificationInvalid.vue'
import ModalNotificationInvalidPostalCode from '@/components/modals/ModalNotificationInvalidPostalCode.vue'
import RegistroLoader from '@/components/loaders/Registro.vue'
import ModalMap from '@/components/modals/ModalMap.vue'
import { mapState, useStore } from 'vuex'
import { mapGetters } from 'vuex'
import { checkoutStepOne,checkoutStepTwo, checkoutStepThree, checkoutStepFour, checkoutStepFive } from '@/pixel/pixel.js'

export default {
    directives: { maska },
    emits:['onShowDetails'],
    computed:{
    ...mapGetters({
            isPostalCodeInvalid  : 'isPostalCodeInvalid',
            isCodeInvalid: 'isCodeInvalid',
            isCustomerValid: 'isCustomerValid',
            error : 'getError',
        }),
    ...mapState({
            selected : state => state.Products.selected,
            address : state => state.Subscription.geo_address,
            loading : state => state.Subscription.loading
        })
    },
    components: {
        Field,
        Form,
        ModalNotification,
        ModalNotificationInvalid,
        ModalNotificationInvalidPostalCode,
        ModalMap,
        RegistroLoader
    },
    setup(props, context ){
        const router = useRouter()
        const store = useStore()
        const activeStep = ref(1)
        const isShow = ref( false )
        const hasSMSCode = ref( false )
        const isShowMap = ref( false )
        const coloniaList = ref([])       
        const telefono_primary = ref('')
        const firstName = ref('')
        const lastName = ref('')
        const middleName = ref('')
        const email = ref('')
        const smsCode =  ref('')
        const stepInformation = ref(null)
        const stepSendSMS  = ref(null)
        const stepValidateCode = ref(null)
        const stepSaveContact = ref(null)
        const stepAddress = ref(null)
        const product =  store.state.Products.selected
        const tagImage = ref('')
        const stepCheckCoverage = store.state.Products.selected.check_cover
        const geo_address = ref({
            colonia: "",
            state:null,
            municipio: null,
            ciudad: null,
            exterior: "",
            interior: "",
            postal_code: "",
            calle : "",
            ccolonia : null,
            reference: null
        })
        const geo_disabled = ref({
            colonia: false,
            state:false,
            municipio: false,
            address: false,
            postal_code : false
        })
        const isRequiredEmail = (  ) => {
            const value = email.value
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;            
            const test =   re.test(value.toLowerCase())

               return value ? test ? true : 'El formato de email no es válido' : 'El formato de email no es válido.' 
        }
        const isRequired = ( value ) =>{
                return value ? true :  'Este campo no lo puedes dejar en blanco.';
        }
        const isRequiredMaxSix = ( value ) => {

                if( value ){
                    if( value.length < 6 ) {
                        return 'Must be 6 Alphanumeric characters only'
                    } else if( /^[a-z\d\-_\s]+$/i.test(value) ) {
                        return true
                    } else{
                        return 'Must be Alphanumeric characters only'
                    }
                } else {
                    return 'Este campo no lo puedes dejar en blanco.';
                } 
        }
        const isRequiredTenDigits = ( value ) => {
            if( value ){
                console.log( value.replace(/ /g,'').replace(/-/g,'').length )
                if( value.replace(/ /g,'').replace(/-/g,'').length == 10 ){
                    if( value.replace(/ /g,'').replace(/-/g,'').length  < 10 ) {
                        return "Tu número debe tener 10 números."
                    } else {
                        return true
                    }
                } else {
                    return "Tu número debe tener 10 números."
                }
            }
            else {
                return value ? true : 'Tu número debe tener 10 números.';
            }
        }
        const isRequiredLettersOnly = (value) => {

            if( /\d/.test(value) ) {
                return "Tu nombre no puede contener números."
                /* eslint-disable-next-line */
            } else if ( /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)){
                return "Tu nombre no puede contener carcateres especiales."
            } else {
                return value ? true : 'Este campo no lo puedes dejar en blanco.';
            }
        }
        const onValidate = (  step ) => {
            
            if( step == 4 ){
                router.push('/pago')
            }
            store.commit( 'SET_SMS_VALIDATION' , null)
            activeStep.value = step
        }

        const onSaveContact = async ( data ) =>{
            checkPixel(4)
            await store.dispatch('saveUserInformationContact', data )
            await store.dispatch('sendValidateCustomer', data )

            let error = store.getters.getError

            if( error ){
                activeStep.value = 2

            } else{
                activeStep.value = 3
            }
            
        }
        const onClose = ( ) => {
            isShow.value = false
        }
         const onCloseSubscriptionError = () => {
            store.commit('SET_ERROR_SUBSCRIPTION', "")
        }
        const onCloseInvalid = () => {
            store.commit('SET_SMS_VALIDATION', null)
        }
       
        const onCloseMap= ( ) => {
            console.log( 'test ')
            isShowMap.value = false
        }

        const onSaveUserInformation = ( data ) =>{
            store.dispatch('saveUserInformation', data )
            checkPixel(1)
            activeStep.value = 2
        }

        const onValidateSMSCode = ( {code} ) => {
            checkPixel(3)
            store.dispatch('sendValidateSMS', {code})
        }

        const onSearchPostalCode = async ( {target} ) =>{
            const postalCode =  target.value
            if( postalCode ){
               await store.dispatch('sendPostalCode', postalCode)
               const address = await store.state.Subscription.geo_address
            //    console.log( address.estadoMunColonias )
               if( address.estadoMunColonias ){
                    geo_address.value.postal_code = postalCode
                    geo_address.value.colonia =  ( address.estadoMunColonias.colonias.length == 1 ? address.estadoMunColonias.colonias[0].colonia : null ) 
                    geo_address.value.ccolonia = ( address.estadoMunColonias.colonias.length == 1 ? address.estadoMunColonias.colonias[0].ccolonia : null )
                    geo_address.value.state =  address.estadoMunColonias.estadoMun.estado
                    geo_address.value.municipio =  address.estadoMunColonias.estadoMun.delmun
               
                    geo_disabled.value.colonia= ( address.estadoMunColonias.colonias.length == 0 ? true : false )  
                    geo_disabled.value.state=  (  address.estadoMunColonias.estadoMun.estado != null ? true : false ) 
                    geo_disabled.value.municipio= ( address.estadoMunColonias.estadoMun.delmun  != null  ? true : false )  

               coloniaList.value =  address.estadoMunColonias.colonias
            //    console.log( coloniaList.value, coloniaList.value.length. geo_address)
               }
            }
        }
        const onEditAddress = ( ) => {
            const product = store.state.Products.selected.check_cover 
             if( !product ) {
                geo_disabled.value.colonia = false
                geo_disabled.value.state =false
                geo_disabled.value.municipio = false
                geo_address.value.colonia = ""
                geo_address.value.state = ""
                geo_address.value.calle = ""
                geo_address.value.municipio = ""
                geo_address.value.ccolonia = ""
                geo_address.value.exterior = ""
                geo_address.value.interior = ""
                geo_address.value.reference = ""
                geo_address.value.postal_code = ""
            } else {
                isShowMap.value = true
            }
        }
        const onSendSMS = ( {telefono} ) =>{
            if( telefono ){
                checkPixel(2)
                telefono = telefono.replace(/ /g,'').replace(/-/g,'')
                telefono_primary.value = telefono
                store.commit('SET_USER_CONTACT_DETAILS', { telefono })
                store.dispatch('sendSMS', telefono)
                hasSMSCode.value = true
            }
        }
        const onSubmitToPago = async ( data ) =>{
            checkPixel(5)
            await store.dispatch('sendRegisterSubscriber' )
            let error = store.getters.getError
            if (!error ){
                if( !geo_address.value.ccolonia  ){
                    data.colonia = geo_address.value.colonia.split('-')[1]
                    data.ccolonia = String(geo_address.value.colonia.split('-')[0])
                } else {
                    data.ccolonia = String(geo_address.value.ccolonia)
                }
                await store.dispatch('sendRegisterDirection', data )
            }

            let error2 = store.getters.getError
            if (!error2 ){
                router.push('/pago')
            }
        }
         const isLetter = (e) => {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z\s]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault();
        }
        const onShowDetails = () =>{
            context.emit('onShowDetails')
        }

        const onSubmtiMobile = async () => {
            let check 
            let isCodeInvalid = store.getters.isCodeInvalid 
            console.log( isCodeInvalid, hasSMSCode , 'stepAc',activeStep.value, 'step add',stepAddress.value)
            switch( activeStep.value  ){
                case 1:
                    check =  await stepInformation.value.validate() 
                    if( check.valid ){
                        let data = {
                            firstName : firstName,
                            lastName : lastName,
                            middleName : middleName
                        }
                        stepInformation.value.onSubmit( data ) 
                    }
                break;
                case 2:
                    if (!hasSMSCode.value ){
                        check =  await stepSendSMS.value.validate()
                        if( check.valid ){
                            console.log( stepSendSMS )
                            stepSendSMS.value.onSubmit( { telefono : telefono_primary.value }) 
                        }
                    } 
                    if( hasSMSCode.value && isCodeInvalid == null  ) {
                        check = await stepValidateCode.value.validate()
                        if( check.valid ){
                            stepValidateCode.value.onSubmit( { code : smsCode.value }) 
                        }
                    }
                    if( hasSMSCode.value && isCodeInvalid ) {
                        check = await stepSaveContact.value.validate()
                        //TODO Here
                        if( check.valid ){
                        let data = {
                            telefonoSecundario : telefono_primary.value,
                            email : email.value 
                        }
                        stepSaveContact.value.onSubmit( data ) 
                        }
                    }
                break;
                case 3: 
                
                if( stepAddress ) {
                    check =  await stepAddress.value.validate()
                     let data = { 
                        calle : geo_address.value.calle,
                        postalCode : geo_address.value.postal_code,
                        ccolonia : geo_address.value.ccolonia,
                        exterior : geo_address.value.exterior,
                        interior  : geo_address.value.interior,
                        colonia :geo_address.value.colonia,
                        municipio : geo_address.value.municipio,
                        ciudad : geo_address.value.state,
                        reference : geo_address.value.reference,
                    }

                    if( check.valid){
                        stepAddress.value.onSubmit( data ) 
                    }
                } else {
                     let data = { 
                        calle : geo_address.value.calle,
                        postalCode : geo_address.value.postal_code,
                        ccolonia : geo_address.value.ccolonia,
                        exterior : geo_address.value.exterior,
                        interior  : geo_address.value.interior,
                        colonia :geo_address.value.colonia,
                        municipio : geo_address.value.municipio,
                        ciudad : geo_address.value.state,
                        reference : geo_address.value.reference,
                    }
                    onSubmitToPago( data ) 
                }
                break;
            }
        }

        const checkGooglAddress = ( ) => {
            const address = store.state.Subscription.googleAddress
            if( address.address_components) {
                const addressComponent = address.address_components.reduce((seed, { long_name, types }) => {
                        types.forEach(t => {
                            seed[t] = long_name;
                        });
                        return seed;
                        }, {})

                geo_address.value.postal_code = addressComponent.postal_code
                geo_address.value.municipio = addressComponent.locality
                geo_address.value.calle = //( addressComponent.street_number ? addressComponent.street_number : '' ) +' '+
                                            (  addressComponent.route  ?  addressComponent.route : '' )
                geo_address.value.exterior = ( addressComponent.street_number ? addressComponent.street_number : '' )
                
                if( geo_address.value.calle.trim() ){
                    geo_disabled.value.address = true
                }
                if( geo_address.value.postal_code ){
                    geo_disabled.value.postal_code = true
                }

                if( geo_address.value.postal_code ){
                    onSearchPostalCode( { target : { value :  geo_address.value.postal_code }}) 
                }
            }
        }

        const setDatalayer = ( ) => {
            /* eslint-disable-next-line */
            dataLayer.push({
                'event' : 'begin_checkout',
                'ecommerce' : {
                    'detail' : {
                        'products' : {
                           name : product.name,
                            price: product.price,
                            id : product.erp_id,
                        }
                    }
                }
            })
        }

          const checkPixel = ( step ) =>{
            switch (step) {
                case 1:
                    checkoutStepOne.tag()
                    checkoutStepOne.floodlight()
                    tagImage.value = checkoutStepOne.image 
                break;
                case 2:
                    checkoutStepTwo.tag()
                    checkoutStepTwo.floodlight()
                    tagImage.value = checkoutStepTwo.image 
                break;
                case 3:
                    checkoutStepThree.tag()
                    checkoutStepThree.floodlight()
                    tagImage.value = checkoutStepThree.image 
                break;
                case 4:
                    checkoutStepFour.tag()
                    checkoutStepFour.floodlight()
                    tagImage.value = checkoutStepFour.image 
                break;
                case 5:
                    checkoutStepFive.tag()
                    checkoutStepFive.floodlight()
                    tagImage.value = checkoutStepFive.image 
                break;
            }
        }

        setDatalayer()
        checkPixel( 1 )
        checkGooglAddress()

        return {
            activeStep,
            tagImage,
            isShow,
            isLetter,
            isShowMap,
            hasSMSCode,
            geo_address,
            telefono_primary,
            email,
            firstName,
            lastName,
            middleName,
            onSubmitToPago,
            onShowDetails,
            onSubmtiMobile,
            stepInformation,
            stepSendSMS,
            stepSaveContact,
            stepValidateCode,
            stepAddress,
            coloniaList,
            geo_disabled,
            smsCode, 
            onSearchPostalCode,
            onValidate,
            onClose,
            onCloseInvalid,
            onCloseSubscriptionError,
            onCloseMap,
            onSendSMS,
            onSaveContact,
            onEditAddress,
            onValidateSMSCode,
            onSaveUserInformation,
            isRequiredLettersOnly,
            isRequiredTenDigits,
            isRequired,
            isRequiredEmail,
            isRequiredMaxSix, 
            stepCheckCoverage}
    }
}
</script>
<style scoped>
.circle-xs{
    padding:5px;
    width: 33px;
    height: 33px;
    @apply md:mr-3 lg:mr-3 xl:mr-3 2xl:mr-3 text-center inline-block;
}
.btn-md{
    padding-left: 1em;
    padding-right: 1em;

}
/*
button{
    @apply xs:hidden sm:hidden lg:visible xl:visible md:visible;
} */
.btn-light-gray-border:hover {
    @apply text-red2 border-red2
}
.btn_validar:hover {
    @apply text-black
}
@media screen and (max-width:767px){
    .btn_enviar {
        @apply border-2 border-red rounded-full py-5 px-8 font-semibold;
        box-shadow: 0;
        transition: box-shadow 0.2s ease-in-out;
        min-width:160px;
    }
    .separator_form {
        width:80%;
        margin: 0 auto;
        margin-bottom:20px
    }
}
</style>