<template>
    <div>
        <h3 class="uppercase text-2xl xs:px-3 sm:px-3 xs:leading-tight sm:leading-tight">GRACIAS POR TU CONTRATACIÓN</h3>
        <div class="separator_form"></div>
        <p class="text-center text-18 resumen_copy xs:px-5 sm:px-5 sm:text-base xs:text-base">
            {{ shipping.plazo_instalacion }}.
        </p>
        
        <!-- <div class="my-5 lg:w-7/12 xl:w-6/12 rounded-2xl mx-auto w-5/12 text-center bg-gray-200 p-5 xs:w-full sm:w-full xs:rounded-none sm:rounded-none">
            <p class="xs:text-18 sm:text-18">Fecha de instalación</p>
            <p class="text-red font-bold text-xl xs:text-2xl sm:text-2xl">{{shipping.orden_instalacion}}</p>
        </div> -->
        <div class="heading_separator"></div>
        <div class="xs:flex xs:flex-col sm:flex sm:flex-col xs:px-6 sm:px-6">
            <p class="font-bold text-center text-xl xs:text-2xl sm:text-2xl xs:px-5 sm:px-5">INFORMACIÓN SOBRE TU PAQUETE</p>
             <p class="text-center text-18 resumen_copy xs:px-5 sm:px-5 sm:text-base xs:text-base"> {{shipping.mensaje_respuesta}}</p>
        
            <p class="text-center mt-5 text-18 font-semibold">Paquete de Televisión <img src="@/assets/images/logo-dish.png" alt="Dish" class="align-baseline inline-block mx-2"></p>
            <div class="w-full max-w-xl bg-gray-200 p-5 my-5 rounded-2xl text-center mx-auto xs:mx-0 sm:mx-auto xs:self-center sm:self-center">
                <p class="text-red font-semibold text-18"> {{ product.nombre }} / {{ product.canales}} Canales</p>
            </div>
<!---
            <p class="font-semibold text-center text-18">Plataformas Digitales</p>
            <div class="w-full max-w-xl sm:w-full bg-gray-200 p-5 mt-2 mb-6 rounded-2xl text-center mx-auto">
                <img src="@/assets/images/logo-amazon-prime.png" alt="Dish" class="inline-block mx-2">
            </div>

            <p class="font-semibold text-center text-18"> Telefonía Celular</p>
            <div class="w-full max-w-xl sm:w-full bg-gray-200 p-5 mt-2 mb-6 rounded-2xl text-center mx-auto flex">
                <div class="flex align-center mr-1 w-1/4">
                    <img  src="@/assets/images/logo-fp.png" alt="freedompop" >
                </div>
                <p class="text-xs mx-3 text-left text-18 w-2/4">7 días de de minutos ilimitados 300 MB de internet y 200 MB de redes sociales</p>
                <p class="ml-1 text-red w-1/4 text-right text-18 font-semibold">Gratis</p>
            </div>
            <div class="grid grid-cols-5 xs:grid-rows-2 sm:grid-rows-2 grid-rows-1 max-w-xl mt-2 mb-6 mx-auto h-16 xs:grid-cols-2 sm:grid-cols-2 xs:h-auto sm:h-auto">
                <div class="flex align-center xs:flex-row xs:justify-end sm:flex-row sm:justify-end bg-gray-200 pl-5 col-start-1 col-end-2 row-start-1 row-end-2 rounded-bl-2xl rounded-tl-2xl xs:col-start-1 xs:col-end-2 sm:col-start-1 sm:col-end-2 xs:row-start-1 xs:row-end-2 sm:row-start-1 sm:row-end-2 xs:h-16 sm:h-16">
                    <div class="flex flex-col justify-center">
                        <img  src="@/assets/images/logo-fp.png" alt="freedompop" >
                    </div>
                </div>
                <p class="text-red xs:text-left sm:text-left text-right text-18 font-semibold bg-gray-200 p-5 col-start-5 col-end-6 row-start-1 row-end-2 flex flex-col justify-center rounded-br-2xl rounded-tr-2xl xs:col-start-2 xs:col-end-3 sm:col-start-2 sm:col-end-3 xs:row-start-1 xs:row-end-2 sm:row-start-1 sm:row-end-2 xs:h-16 sm:h-16">Gratis</p>
                <p class="text-xs xs:text-sm sm:text-sm text-left bg-gray-200 p-5 xs:pt-2 sm:pt-2 col-start-2 col-end-5 row-start-1 row-end-2 flex flex-col justify-center xs:block sm:block xs:col-start-1 xs:col-end-3 xs:row-start-2 xs:row-end-2 sm:col-start-1 sm:col-end-3 sm:row-start-2 sm:row-end-2 xs:bg-white sm:bg-white xs:text-center sm:text-center">7 días de de minutos ilimitados 300 MB de internet y 200 MB de redes sociales</p>
            </div>

            <p class="font-semibold text-center"> Otros Servicios</p>
            <div class="w-full max-w-xl xs:w-full sm:w-full bg-gray-200 p-5 mt-2 mb-6 rounded-2xl text-center mx-auto flex">
                <div class="flex align-center mr-1 w-1/4">
                    <img  src="@/assets/images/logo-dm.png" alt="Dish mobil" >
                </div>
                <p class="text-xs mx-3 text-left w-2/4">Lleva toda la programación de tu paquete Dish en tu smartphone</p>
                <p class="ml-1 text-red w-1/4 text-right text-18 font-semibold">Gratis</p>
            </div>
    
            <div class="resumen_grid _2 grid grid-cols-5 xs:grid-rows-3 xs:grid-cols-1 sm:grid-cols-1 sm:grid-rows-3 grid-rows-1 max-w-xl mt-2 mb-6 mx-auto h-16 xs:h-auto sm:h-auto sm:w-full">
                <div class="flex align-center bg-gray-200 pl-5 xs:pr-5 sm:pr-5 xs:pt-4 sm:pt-4 col-start-1 col-end-2 row-start-1 row-end-2 xs:col-start-1 xs:col-end-2 xs:row-start-1 xs:row-end-2 sm:col-start-1 sm:col-end-2 sm:row-start-1 sm:row-end-2 rounded-bl-2xl rounded-tl-2xl xs:rounded-tr-2xl xs:rounded-bl-none  sm:rounded-tr-2xl sm:rounded-bl-none">
                    <div class="flex flex-col justify-center xs:flex-row xs:w-full sm:flex-row sm:w-full ">
                        <img  src="@/assets/images/logo-dm.png" alt="Dish mobil" >
                    </div>
                </div>
                <p class="xs:flex-row xs:w-full sm:flex-row sm:w-full xs:text-xl sm:text-xl  text-red text-right text-18 xs:pt-2 sm:pt-2 xs:pb-4 sm:pb-4 font-semibold xs:p-0 sm:p-0 bg-gray-200 p-5 col-start-5 col-end-6 row-start-1 row-end-2 flex flex-col justify-center sm:col-start-1 sm:col-end-3 sm:row-start-2 sm:row-end-3 xs:col-start-1 xs:col-end-3 xs:row-start-2 xs:row-end-3 rounded-br-2xl rounded-tr-2xl xs:rounded-br-2xl xs:rounded-bl-2xl xs:rounded-tr-none sm:rounded-br-2xl sm:rounded-bl-2xl sm:rounded-tr-none">Gratis</p>
                <p class="text-xs text-left bg-gray-200  xs:pt-3 sm:pt-3 p-5 col-start-2 col-end-5 row-start-1 row-end-2  xs:col-start-1 xs:col-end-3 xs:row-start-3 xs:row-end-4 sm:col-start-1 sm:col-end-3 sm:row-start-3 sm:row-end-4 flex flex-col justify-center xs:bg-white sm:bg-white xs:text-center sm:text-center xs:text-sm sm:text-sm ">Lleva toda la programación de tu paquete Dish en tu smartphone</p>
            </div>
        
            <p class="font-semibold text-center">Equipos Adicionales</p>
            <div class="xs:flex-col xs:justify-center sm:flex-col sm:justify-center  w-full max-w-xl xs:w-full sm:w-full bg-gray-200 p-5 mt-2 mb-6 rounded-2xl text-center mx-auto flex justify-between">
                <p> Decodificador Dish adicional </p>
                <p class="text-18 font-semibold"> 1 </p>
            </div>
     -->
            <div class="w-full max-w-xl mx-auto  p-5 my-5 flex justify-between">
                <div>
                    <p class="font-semibold text-18">Gastos de Instalación</p>
                </div>
                <div class="text-right">
                    <p class="text-blue text-18 font-semibold">Pago único de  ${{ parseFloat(  product.suscripcion ).toFixed(2) }}  </p>
                    <!-- <p class="text-red text-sm font-semibold xs:font-normal sm:font-normal xs:underline sm:underline">Ahorras $99.00</p> -->
                </div>
            </div>
   
            <div class="w-full max-w-xl  bg-gray-200 p-5 mt-2 mb-6 rounded-2xl text-center mx-auto flex justify-between">
                <div> <p class="font-semibold text-18">Costo Mensual:</p> </div>
                <div class="text-right">
                    <p class="font-bold text-18" v-if="paymentMethod != 'efectivo'"> ${{ parseFloat(  product.precio ).toFixed(2)}} </p>
                    <p class="font-bold text-18" v-if="paymentMethod == 'efectivo'"> ${{ parseFloat(  effectivoPrice.monto ).toFixed(2)}} </p>
                </div>
            </div>
        </div>
        <transition name="fade">
            <ModalNotificationInvalidPostalCode v-show="error" :message="error" @onClose="onCloseSubscriptionError"/> 
        </transition>
    </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { mapGetters, mapState, useStore } from 'vuex'
import ModalNotificationInvalidPostalCode from '@/components/modals/ModalNotificationInvalidPostalCode.vue'

export default {
    computed:{
    ...mapState({
            shipping : state => state.Subscription.shipping_information,
            effectivoPrice: state => state.Subscription.effectivo_price,
            paymentMethod: state => state.Subscription.payment_method
        }),
    ...mapGetters({
        product : 'getProductInformation',
        error : 'getError'
    })
    },
    components:{
        ModalNotificationInvalidPostalCode
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const folio = store.getters.getFolio;
        const product =  store.state.Products.selected

        if ( Object.keys(folio).length == 1 ){
            console.log( router)
            //router.push( { name : 'Home'}) 
        }

        const setDatalayer = ( ) => {
            /* eslint-disable-next-line */
            dataLayer.push({
                'event' : 'purchase',
                'ecommerce' : {
                    'detail' : {
                        'products' : {
                           name : product.name,
                            price: product.price,
                            id : product.erp_id,
                        }
                    }
                }
            })
        }

        setDatalayer()


        store.dispatch('sendStatusShipping')
        store.dispatch('sendShipping')
        store.commit('SET_SUBSCRIPTION_COMPLETE', true)
        
        const onCloseSubscriptionError = () => {
            store.commit('SET_ERROR_SUBSCRIPTION', "")
        }

        return { onCloseSubscriptionError  }

    },
}
</script>
<style scoped>
    .separator_form {
        @apply m-auto mb-6;
        max-width: 520px;
    }
    .resumen_copy {
        @apply m-auto;
        max-width: 600px;
        line-height: 27px;
    }
    @media screen and (max-width:767px){
        .separator_form {
            width:80%;
            margin: 0 auto;
            margin-bottom:20px
        }
        .resumen_grid._2 {
            grid-template-rows: auto auto 1fr;
        }
    }
</style>