export const getPayment = ( state) => {
    return state.payment
}
export const getRegistration = ( state ) => {
    return state.registraion
}

export const isCodeInvalid = ( state ) => {
    return state.sms_validation
}

export const isPostalCodeInvalid = ( state ) => {
    return ( state.error ? true : false )
}
export const getError = ( state ) => {
    return state.error
}

export const isCustomerValid = ( state ) => {
    return state.customer_validation
}
export const getCellularCode = ( state ) => {
    return state.registraion
}
export const getGoogleAddress = ( state ) => {
   const address = state.googleAddress

   if( address.address_components) {
    return  address.address_components.reduce((seed, { long_name, types }) => {
            types.forEach(t => {
                seed[t] = long_name;
            });
            return seed;
            }, {})
    } else {
        return []
    }

}
export const getFolio =  ( state ) => {
    const local = localStorage.getItem('SET_SUBSCRIBER_REGISTRATION');
    return  ( local ? JSON.parse(local) :  state.subscriber_registration )
}