<template>
  <div class="flex justify-end page_registro _details xs:h-full sm:h-full">
    <template v-if="loading">
      <DetailsLoader />
    </template>
    <template v-else>
      <div
        class="
          bg-gray-250
          py-5
          px-10
          rounded-xl
          details_container
          xs:rounded-none
          sm:rounded-none
        "
        v-if="information"
      >
        <h3 class="text-red uppercase text-2xl">Detalles de tu paquete</h3>
        <div class="separator_form"></div>
        <div class="w-full">
          <p>Paquete de Televisión</p>
          <div
            class="
              flex
              rounded-3xl
              justify-between
              bg-gray-150
              px-5
              py-4
              my-2
              items-center
              max-h-13
            " v-if="paymentMethod != 'efectivo'"
          >
            <p class="text-red">
              {{ information.nombre }} / Hasta {{ information.canales }} Canales
            </p>
            <p class="text-darkgray-400">
              {{ parseFloat(information.precio).toFixed(2) }} /mes
            </p>
          </div>



          <div 
            class="
              flex
              rounded-3xl
              justify-between
              bg-gray-150
              px-5
              py-4
              my-2
              items-center
              max-h-13
            " v-if="paymentMethod == 'efectivo'"
          >
            <p class="text-red">
              {{ information.nombre }} / Hasta {{ information.canales }} Canales
            </p>
            <p class="text-darkgray-400">
              {{ parseFloat(information.precio_efectivo).toFixed(2) }} /mes
            </p>
          </div>


        </div>
        <!-- <div class="w-full">
                    <p> Plataformas Digitales </p>
                    <div class="flex rounded-3xl justify-between  bg-gray-150  px-5 py-4 my-2 items-center max-h-13">
                        <p class="text-darkgray-400"> +$89.00 /mes</p>
                    </div>
                </div>
                <div class="w-full">
                    <p> Telefonía Celular </p>
                    <div class="flex rounded-3xl justify-between  bg-gray-150  px-5 py-4 my-2 items-center max-h-13">
                        <img src="@/assets/images/logo-freedompop-colored.svg" alt="Dish mobil"  style="max-height:25px;">
                        <p class="text-red" style="font-size:14px">Chip de regalo</p>
                        <a href="#" class="text-red text-base"> Gratis </a>
                    </div>
                    <a href="#" class="text-xs underline"> Agrega un plan <span class="font-bold">Freedom Pop</span> desde $30 pesos al mes </a>
                </div>
                <div class="w-full">
                    <p> Otros Servicios </p>
                    <div class="flex rounded-3xl justify-between  bg-gray-150  px-5 py-4 my-2 items-center max-h-13">
                        <img src="@/assets/images/logo-dishmobil.svg " class="mr-2" alt="Dish mobil" width="100">
                        <p class="mr-2" style="font-size:12px"> Lleva toda la programación de tu paquete en tu smartphone </p>
                        <a href="#" class="text-red mr-2"> Gratis </a>
                    </div>
                </div>
                <div class="w-full">
                    <p> Equipos Adicionales </p>
                    <div class="flex rounded-3xl justify-between bg-gray-150  px-5 py-4 my-2 items-center max-h-13">
                        <p class="mr-2" style="font-size:12px; width:190px; line-height:1rem">Más pantallas con tu paquete de TV por $95.00 al mes</p>
                        <div class="text-center mr-2 flex" style="width:100px">
                            <button class="bg-gray-210 rounder-top-right-xl px-2 rounded-bl-2xl rounded-tl-2xl" style="height:28px"> - </button>
                            <input type="number" placeholder="1" class="place-text-center text-center text-black text-xs bg-gray-210" style="text-indent:10px; padding:0px; height:28px; border-radius:0px; width:60px; min-width:60px; margin:0px">
                            <button class="bg-gray-210 rounder-top-right-xl px-2  rounded-br-2xl rounded-tr-2xl " style="height:28px"> +</button>
                        </div>
                        <a href="#" class="text-red w-3/12 text-right" > +$99 / mes </a>
                    </div>
                </div> -->
        <div class="separator_form" style="margin-top: 20px"></div>
        <div class="w-full">
          <div
            class="flex justify-between my-3"
            v-if="paymentMethod != 'efectivo'"
          >
            <p class="font-medium">Costo Mensual:</p>
            <div class="text-right">
              <p>${{ information.precio }}</p>
              <!-- <a href="#" class="text-red text-xs underline"> Ahorras $99.00 / mes </a>  -->
            </div>
          </div>
          <div
            class="flex justify-between my-3"
            v-if="paymentMethod == 'efectivo'"
          >
            <p class="font-medium">Costo Mensual:</p>
            <div class="text-right">
              <p>${{ information.precio_efectivo }}</p>
              <!-- <a href="#" class="text-red text-xs underline"> Ahorras $99.00 / mes </a>  -->
            </div>
          </div>

          <div class="flex justify-between my-3">
            <p class="font-medium">Gastos de Instalación:</p>
            <div class="text-right" v-if="paymentMethod == 'efectivo'">
              <p>${{ parseFloat(parseInt(effectivoPrice)).toFixed(2) }}</p>
              <!-- <p>Pago único $1.00</p>
                            <a href="#" class="text-red text-xs underline"> Ahorras $99.00 </a>  -->
            </div>
            <div class="text-right" v-else>
              <p>${{ parseFloat(information.suscripcion).toFixed(2) }}</p>
              <!-- <p>Pago único $1.00</p>
                            <a href="#" class="text-red text-xs underline"> Ahorras $99.00 </a>  -->
            </div>
          </div>

          <div
            class="flex justify-between my-3"
            v-if="paymentMethod != 'efectivo'"
          >
            <p class="font-medium text-2xl">Total a pagar</p>
            <p class="text-2xl">
              ${{ parseFloat(parseInt(information.suscripcion)).toFixed(2) }}
            </p>
          </div>
          <div
            class="flex justify-between my-3"
            v-if="paymentMethod == 'efectivo'"
          >
            <p class="font-medium text-2xl">Total a pagar</p>
            <p class="text-2xl">
              ${{ parseFloat(parseInt(effectivoPrice)).toFixed(2) }}
            </p>
          </div>
        </div>
        <div v-html="tagImage"></div>
      </div>
    </template>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { mapGetters, mapState, useStore } from "vuex";
import DetailsLoader from "@/components/loaders/Details.vue";
import { packageButton } from "@/pixel/pixel.js";
import { ref } from "@vue/reactivity";

export default {
  components: { DetailsLoader },
  computed: {
    ...mapState({
      loading: (state) => state.Products.loading,
      effectivoPrice: (state) => state.Subscription.suscripcion_efectivo,
      paymentMethod: (state) => state.Subscription.payment_method,
    }),
    ...mapGetters({
      information: "getProductInformation",
    }),
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const tagImage = ref("");
    let packageInfo = store.state.Products.selected;
    const packagesData = store.state.Home.packages.dataObj;

    const checkRoute = async () => {
      if (route.name == "RegisterCode") {
        if (!packagesData) {
          store.state.Products.loading = true;
          await store.dispatch("callPackagesHomeData", 16);
          const packageList = await store.getters.getPackageDataList.list;
          packageInfo = packageList.find((data) => {
            return data.package.erp_id == route.params.package_code;
          });

          if (packageInfo) {
            store.state.Products.loading = false;

            packageButton.tag();
            const image =
              packageButton.image +
              `<img src="${
                packageButton.postback + route.params.package_code
              }"/> `;
            tagImage.value = image;

            store.dispatch("setProduct", packageInfo.package);
            store.dispatch(
              "callPackageInformation",
              packageInfo.package.package_id
            );
          } else {
            store.state.Products.loading = false;
            router.push({ name: "Home" });
          }
        }
      } else {
        if (Object.keys(packageInfo).length == 0) {
          router.push({ name: "Home" });
        } else {
          store.dispatch("callPackageInformation", packageInfo.package_id);
        }
      }
    };
    checkRoute();
    return { packageInfo, tagImage };
  },
};
</script>
<style scoped>
.page_registro._details p {
  @apply font-medium;
}
div.w-full {
  @apply mt-5;
}
.details_container {
  width: 586px;
  @apply md:w-full lg:w-full;
}
@media screen and (max-width: 767px) {
  .page_registro._details {
    min-height: 100vh;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .page_registro._details {
    min-height: auto;
  }
}
</style>