<template>
  <div>
    <Loading v-model:active="Token.loading"
      :can-cancel="true"
      :loader="'dots'"
      :color="'#D6212E'"
      :height="130"
      :width="130"
      :opacity="'0.3'"
      :is-full-page="true">  </Loading>
    <router-view v-slot="{ Component }">
      <transition >
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { mapState } from 'vuex';

export default {
  name: 'Dish',
  components: {
      Loading
  },
  computed: {
    ...mapState(['Token'] )
  },
  setup() {
  }
}

</script>