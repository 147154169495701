<template>
  <div class="home" :style="mobileBodyHeight"> 
    <Loading :active="loading"
      :can-cancel="true"
      :loader="'dots'"
      :color="'#D6212E'"
      :height="130"
      :width="130"
      :opacity="'0.3'"
      :is-full-page="true" 
      > </Loading>
    <Header></Header>
    <div class="home-component">
    <component
        v-for="(component,i) in componentData"
        :key="i"
        :is="component.name"
        :data="component.data"
      />
    </div>
    <Footer></Footer> 
  </div>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Iframe from '@/components/Iframe.vue'
import CarouselHeroBanner from '@/components/carousel/hero-banner.vue'
import CarouselPromotions from '@/components/carousel/promotions-banner.vue'
import CarouselDishPackages from '@/components/carousel/product-packages.vue'
import CarouselPromotionsBanner from '@/components/carousel/promotions-slim.vue'
import TitleComponent from '@/components/Title.vue'
import SearchComponent from '@/components/Search.vue'
import Loading from 'vue-loading-overlay';
import { mapGetters, mapState, useStore } from 'vuex'
import { watch, computed } from 'vue'
import 'vue-loading-overlay/dist/vue-loading.css';
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'Home',
  data(){
    return {
      toggle: false,
      switchFormData : {
        label : '',
        checked: false 
      }
    }
  },
  components: {
    Loading,
    Header,
    Footer,
    Iframe,
    CarouselPromotions,
    CarouselHeroBanner,
    CarouselDishPackages,
    CarouselPromotionsBanner,
    TitleComponent,
    SearchComponent,
  },
  watch:{
    value( val ){
      console.log( val )
    }
  },
  computed:{
    ...mapGetters({
      componentData: 'getComponentData',
      mobileBodyHeight: 'GET_MOBILE_BODY_HEIGHT'
    }),
    ...mapState({
      loading: state => state.Home.loading ,
    }),
  },
  setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const pageNumber = computed(() => {
        return  store.state.Home.pageNumber  
      })
      watch(pageNumber, (val ) => {
        const pageNumber = { value : val }
        store.dispatch( 'callHomeData', pageNumber  )
      }) 
      const callData = () => {
        store.dispatch( 'callNavbarData' )
        store.dispatch( 'callFooterData' )
        store.dispatch( 'callHomeData', pageNumber )
      }
      const getSlug = async () => {
        if( route.params.pathMatch ){
          const slug =  route.params.pathMatch[0]
          const exist = await store.dispatch('callAllPages', slug)
          if ( exist ) {
              callData()
          } else {
            router.push( {name: "PageNotFound"} )
          }
        } else {
          callData()
        }
      }
   
     getSlug()

  }
}
</script>
<style scoped>
.home-component{
  min-height: 80vh;
  position: relative;
}


</style>
