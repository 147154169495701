<template>
  <div class="p-5">
    <h3 class="uppercase text-2xl">MÉTODO DE PAGO</h3>
    <div class="separator_form"></div>
    <div v-html="tagImage"></div>
    <div class="step">
      <PagoLoader v-show="loading"> </PagoLoader>
      <div v-show="!loading">
        <p class="font-semibold text-18 mb-5 sm:text-center xs:text-center">
          Elige tu Método de Pago
        </p>

        <Form
          v-slot="{ errors }"
          @submit="onSubmit"
          v-show="selectedPackage.check_tdb"
        >
          <div class="my-3">
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                rounded-full
                w-8/12
                xs:w-full
                sm:w-full
                lg:w-10/12
                flex
                items-center
              "
              :class="{ active: paymentMethod == 'card' }"
            >
              <Field
                name="paymentMethod"
                type="radio"
                value="card"
                class="mr-3"
                v-model="paymentMethod"
              />
              <label for="paymentMethod" class="font-medium">
                Tarjeta de Crédito</label
              >
            </div>
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                pt-6
                rounded-br-3xl rounded-bl-3xl
                transition-all
                text-center
              "
              v-if="paymentMethod == 'card'"
            >
              <div class="w-full">
                <Field
                  name="card"
                  type="text"
                  v-maska="'#### - #### - #### - ####'"
                  :rules="isRequiredCard"
                  class="
                    tracking-widest
                    text-center
                    bg-white
                    font-gray-450
                    p-4
                    my-3
                    rounded-full
                    w-full
                  "
                  placeholder="1234-1234-1234-1234"
                />
              </div>
              <span class="text-red">{{ errors.carddb }}</span>

              <Field
                name="cardName"
                type="text"
                v-model="cardName"
                v-on:keypress="isLetter"
                :rules="isRequiredLettersOnly"
                pattern="[a-zA-Z]*"
                class="
                  tracking-widest
                  text-center
                  bg-white
                  font-gray-450
                  p-4
                  my-3
                  rounded-full
                  w-full
                "
                placeholder="Juan Jesus Martinez"
              />
              <span class="text-red">{{ errors.cardName }}</span>

              <p class="my-3">
                Al dar clic en <span class="font-bold"> “Pagar” </span> serás
                remitido a un portal seguro para realizar tu pago. Una vez
                realizado el pago, regresarás a nuestro portal con la
                confirmación de tu contratación.
              </p>
              <div class="xs:flex-col xs:flex sm:flex-col sm:flex">
                <button
                  type="submit"
                  class="
                    btn btn-red
                    my-5
                    xs:w-8/12
                    sm:w-8/12
                    xs:self-center
                    sm:self-center
                  "
                >
                  Pagar ${{ parseFloat(parseInt(information.suscripcion)) }}
                </button>
              </div>
            </div>
          </div>
        </Form>
        <Form
          v-slot="{ errors }"
          @submit="onSubmit"
          v-show="selectedPackage.check_rpef && showEffectivo"
        >
          <div class="my-3 text-center">
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                rounded-full
                w-8/12
                xs:w-full
                sm:w-full
                lg:w-10/12
                flex
                items-center
              "
              :class="{ active: paymentMethod == 'efectivo' }"
            >
              <Field
                name="paymentMethod"
                type="radio"
                value="efectivo"
                class="mr-3"
                v-model="paymentMethod"
              />
              <label for="paymentMethod" class="font-medium">
                Pago en Efectivo
              </label>
              <span>{{ errors.field }}</span>
            </div>
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pt-6
                pl-6
                rounded-br-3xl rounded-bl-3xl
                transition-all
              "
              v-if="paymentMethod == 'efectivo'"
            >
              <p class="text-sm">
                Paga en cualquiera de nuestros lugares de pago presentando el
                siguiente código de barras:
              </p>
              <img :src="barcode.qr_code" alt="barcode" class="mx-auto my-5" />
              <p class="text-center text-lg font-bold">
                {{ barcode.referencia }}
              </p>
              <p class="text-center underline mt-4 text-xs">
                Una vez realizado el pago tu paquete quedara habilitado
              </p>
              <div class="text-center">
                <button class="btn btn-red my-5 mx-auto" type="submit">
                  Enviar a mi celular
                </button>
              </div>
              <p class="my-3 text-sm mb-0">
                Estos son algunos de los lugares donde puedes realizar tu pago:
              </p>
              <div class="flex mt-5 justify-center">
                <div class="w-3/12">
                  <img src="@/assets/images/stores/bbva.png" alt="" srcset="" />
                </div>
                <div class="w-3/12">
                  <img
                    src="@/assets/images/stores/chedraui.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="w-3/12">
                  <img
                    src="@/assets/images/stores/farmacias_ahorro.png"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="flex mt-5 justify-center">
                <div class="w-3/12">
                  <img src="@/assets/images/stores/hsbc.png" alt="" srcset="" />
                </div>
                <div class="w-3/12">
                  <img src="@/assets/images/stores/oxxo.png" alt="" srcset="" />
                </div>
                <div class="w-3/12">
                  <img
                    src="@/assets/images/stores/walmart.png"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <p class="text-center underline mt-4 mb-3">
                <a
                  href="https://dish.com.mx/soporte-y-servicio/lugares-de-pago-dish"
                  target="_blank"
                  >Consulta aquí todos los lugares de pago</a
                >
              </p>
            </div>
          </div>
        </Form>
        <Form
          v-slot="{ errors }"
          @submit="onSubmit"
          v-show="selectedPackage.check_rtl"
        >
          <div class="my-3 text-center">
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                rounded-full
                w-8/12
                xs:w-full
                sm:w-full
                lg:w-10/12
                flex
                items-center
              "
              :class="{ active: paymentMethod == 'telmex' }"
            >
              <Field
                name="paymentMethod"
                type="radio"
                value="telmex"
                class="mr-3"
                v-model="paymentMethod"
              />
              <label for="paymentMethod" class="font-medium">
                Recibo telefónico Telmex
              </label>
              <span>{{ errors.paymentMethod }}</span>
            </div>
            <div
              class="
                bg-gray-450
                md:w-full
                p-4
                pt-6
                pl-6
                rounded-br-3xl rounded-bl-3xl
                transition-all
              "
              v-if="paymentMethod == 'telmex'"
            >
              <p class="text-sm">
                Ingresa el número telefónico al que está registrado tu
                <span class="font-bold"> Recibo Telmex </span> para pagar
                mensualmente.
              </p>
              <p class="underline mt-4">Número Telefónico (10 dígitos)*</p>
              <Field
                name="telmex"
                type="text"
                v-maska="'#### - #### - ##'"
                class="
                  bg-white
                  font-gray-900
                  xs:w-full
                  sm:w-full
                  p-4
                  my-3
                  rounded-full
                  w-8/12
                "
                aria-placeholder="0000 - 0000 - 00"
                placeholder="0000 - 0000 - 00"
                :rules="isRequiredTenDigits"
              />
              <div>{{ errors.telmex }}</div>
              <div class="xs:flex xs:flex-col">
                <button
                  type="submit"
                  class="
                    btn btn-red
                    my-5
                    xs:self-center
                    sm:self-center
                    xs:w-8/12
                    sm:w-8/12
                  "
                >
                  Verificar
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <!-- https://www.procom.prosa.com.mx/eMerch2/7480527_dish.jsp -->
      <form
        method="POST"
        action="https://www.procom.prosa.com.mx/eMerchant/7480527_dish.jsp"
        id="formProsa"
      >
        <input type="hidden" name="merchant" :value="signatureData.merchant" />
        <input type="hidden" name="store" :value="signatureData.store" />
        <input type="hidden" name="term" :value="signatureData.term" />
        <input type="hidden" name="total" :value="signatureData.total" />
        <input type="hidden" name="currency" :value="signatureData.currency" />
        <input type="hidden" name="order_id" :value="signatureData.orderId" />
        <input type="hidden" name="digest" :value="signatureData.digest" />
        <input type="hidden" name="urlBack" :value="signatureData.url" />
      </form>
      <transition name="fade">
        <ModalNotificationInvalidPostalCode
          v-show="error"
          :message="error"
          @onClose="onCloseSubscriptionError"
        />
      </transition>
      <transition name="fade">
        <ModalPaymentEfectivo
          v-if="isSuccessPaymentEffectivo"
          :barcode="barcode"
          :barCodeReference="barCodeReference"
          @onClose="onCloseModalEfectivo"
        />
      </transition>
    </div>
  </div>
</template>
<script>
import { maska } from "maska";
import { Form, Field } from "vee-validate";
import { mapState, mapGetters, useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import PagoLoader from "@/components/loaders/Pago.vue";
import ModalNotificationInvalidPostalCode from "@/components/modals/ModalNotificationInvalidPostalCode.vue";
import ModalPaymentEfectivo from "@/components/modals/ModalPaymentEfectivo.vue";
import { pagoEffectivo, pagar, checkoutStepSix } from "@/pixel/pixel.js";
import { watch } from "@vue/runtime-core";
export default {
  directives: { maska },
  computed: {
    ...mapGetters({
      error: "getError",
    }),
    ...mapState({
      loading: (state) => state.Subscription.loading,
      information: (state) => state.Products.information,
      barcode: (state) => state.Subscription.barcode,
      selectedPackage: (state) => state.Products.selected,
    }),
  },
  components: {
    Field,
    Form,
    PagoLoader,
    ModalNotificationInvalidPostalCode,
    ModalPaymentEfectivo,
  },

  setup() {
    const cardName = ref("");
    const store = useStore();
    const router = useRouter();
    const paymentMethod = ref("");
    const signatureData = ref({});
    const ccRecurring = ref(true);
    const isSuccessPaymentEffectivo = ref(false);
    const barCodeReference = ref({ dataObj: { sms: null } });
    const product = store.state.Products.selected;
    const showEffectivo = ref(true);
    const tagImage = ref("");

    const isRequiredCard = (value) => {
      if (value) {
        if (value.replace(/ /g, "").replace(/-/g, "").length > 0) {
          if (value.replace(/ /g, "").replace(/-/g, "").length < 15) {
            return "Tu número debe tener 15 - 16 números.";
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return "Este campo no lo puedes dejar en blanco.";
      }
    };
    const isRequired = (value) => {
      return value ? true : "Este campo no lo puedes dejar en blanco.";
    };
    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/dev" +
      "/resumen";
    const isRequiredLettersOnly = (value) => {
      if (/\d/.test(value)) {
        return "Tu nombre no puede contener números.";
        /* eslint-disable-next-line */
      } else if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
        return "Tu nombre no puede contener personajes especiales.";
      } else {
        return value ? true : "Este campo no lo puedes dejar en blanco.";
      }
    };
    const isRequiredTenDigits = (value) => {
      if (value) {
        if (value.replace(/ /g, "").replace(/-/g, "").length < 0) {
          if (value.replace(/ /g, "").replace(/-/g, "").length < 10) {
            return "Tu número debe tener 10 números.";
          }
        }
        return true;
      } else {
        return value ? true : "Tu número debe tener 10 números.";
      }
    };
    const onCloseSubscriptionError = () => {
      store.commit("SET_ERROR_SUBSCRIPTION", "");
    };
    const onSubmit = async (data) => {
      let error = store.getters.getError;

      pagar.tag();
      pagar.floodlight();
      tagImage.value = pagar.image;

      if (paymentMethod.value == "card") {
        await store.dispatch("generateSignaturePay");
        await store.dispatch("setRegisterMethod", data);

        let register = await store.state.Subscription.register_signature;
        if (register.dataObj) {
          register = register.dataObj;
          signatureData.value = {
            currency: register.currency,
            digest: register.digest,
            merchant: register.merchant,
            store: register.store,
            term: register.term,
            orderId: register.orderId,
            total: register.total,
            url: url,
          };
        }

        error = await store.getters.getError;
        if (!error) {
          store.commit("SET_LOADING", true);
          setTimeout(() => {
            document.getElementById("formProsa").submit();
          }, 2000);
        }
      } else if (paymentMethod.value == "carddb") {
        await store.dispatch("generateSignaturePay");
        await store.dispatch("setRegisterMethod", data);

        store.commit("SET_LOADING", true);
        let register = await store.state.Subscription.register_signature;
        if (register.dataObj) {
          register = register.dataObj;
          signatureData.value = {
            currency: register.currency,
            digest: register.digest,
            merchant: register.merchant,
            store: register.store,
            term: register.term,
            orderId: register.orderId,
            total: register.total,
            url: url,
          };
        }

        error = await store.getters.getError;

        if (!error) {
          store.commit("SET_LOADING", true);
          setTimeout(() => {
            document.getElementById("formProsa").submit();
          }, 2000);
        }
      } else if (paymentMethod.value == "telmex") {
        await store.dispatch("validateTelmex", data);
        error = await store.getters.getError;

        if (error) {
          return;
        }

        await store.dispatch("setRegisterMethod", data);
        error = await store.getters.getError;

        if (!error) {
          router.push("/resumen");
        }
      } else {
        await store.dispatch("setRegisterMethod", data);
        await store.dispatch("sendReferencePayment");

        error = await store.getters.getError;

        if (!error) {
          barCodeReference.value = await store.state.Subscription
            .barcode_sms_reference;
          isSuccessPaymentEffectivo.value = true;
        }
      }
    };

    const isLetter = (e) => {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z\s]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    };

    const onCloseModalEfectivo = () => {
      isSuccessPaymentEffectivo.value = false;
    };

    const setDatalayer = () => {
      /* eslint-disable-next-line */
      dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
          detail: {
            products: {
              name: product.name,
              price: product.price,
              id: product.erp_id,
            },
          },
        },
      });

      checkoutStepSix.tag();
      checkoutStepSix.floodlight();
      tagImage.value = checkoutStepSix.image;
    };

    const checkEffectivo = async () => {
        console.log("check efectivo"); 
      const x = await store.dispatch("getSuscriptionEfective");
      console.log(store.state.Subscription.suscripcion_efectivo);
      showEffectivo.value = !x ? false : true;
    };

    const setEfectivoControls = async () => {
        console.log("efectivo controls");

      pagoEffectivo.tag();
      pagoEffectivo.floodlight();
      tagImage.value = pagoEffectivo.image;
      if (Object.keys(store.state.Subscription.register_method) == 0) {
        await store.dispatch("generateBarcode");
        await store.dispatch("getSuscriptionEfective");
        await store.dispatch("setRegisterMethod", {
          paymentMethod: "efectivo",
        });
        await store.dispatch("sendStatusShipping");
        await store.dispatch("sendShipping");
      }
    };

    watch(paymentMethod, async (val) => {
      store.commit("SET_PAYMENT_METHOD", val);
      if (val == "efectivo") {
        setEfectivoControls();
      }
      //   return val
    });

    checkEffectivo();
    setDatalayer();

    return {
      tagImage,
      cardName,
      onSubmit,
      showEffectivo,
      ccRecurring,
      isRequired,
      isRequiredLettersOnly,
      isRequiredTenDigits,
      isSuccessPaymentEffectivo,
      barCodeReference,
      paymentMethod,
      onCloseSubscriptionError,
      signatureData,
      window,
      isLetter,
      onCloseModalEfectivo,
      isRequiredCard,
    };
  },
};
</script>
<style scoped>
.active {
  @apply rounded-br-none rounded-bl-none bg-red text-white rounded-tr-2xl  rounded-tl-2xl w-full;
}
input[type="radio"] {
  @apply relative;
  width: 20px;
  height: 20px;
  cursor: pointer !important;
}
h3,
.separator_form {
  max-width: 440px;
}
input[type="radio"]:checked:after {
  @apply left-0 top-0 bg-black rounded-full relative visible border-white border-solid inline-block border-2;
  width: 20px;
  height: 20px;
  content: "";
}
.separator {
  @apply w-full border-solid border border-gray-300;
}
.pago_cards {
  gap: 10px;
}
.pago_cards input {
  flex-basis: 32%;
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .pago_cards input {
    flex-basis: 31%;
  }
}
@media screen and (max-width: 480px) {
  .pago_cards input {
    flex-basis: 48%;
  }
  .separator_form {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .pago_cards input {
    flex-basis: 48%;
  }
  .separator_form {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
</style>