export default{
    registration: null,
    payment:  null,
    sms:null,
    loading: false,
    isOn : false,
    details: null,
    error: "",
    customer_validation: false,
    shipping_information: {
        plazo_instalacion : null,
        orden_instalacion: null,
        mensaje_respuesta: null,
    },
    user_registration: {postalCode : '01010' },
    googleAddress: {},
    sms_validation: null,
    telmex_number: null,
    product_registration: {},
    subscriber_registration: { dataObj : { folio : 123 }},
    subscriber_complete: false,
    direction_registration: {},
    register_signature: {},
    register_method: {},
    geo_postal_code : [],
    geo_address: {},
    page: {},
    barcode: "",
    barcode_sms_reference : {},
    effectivo_price: {},
    payment_method: null,
    suscripcion_efectivo: {} 
}