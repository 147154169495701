<template>
    <header id="header" class="sm:h-14 md:h-14 xs:w-full sm:w-full md:w-full flex justify-center xs:justify-start sm:justify-start items-center shadow-md xs:py-2 sm:py-2 xs:px-8 px-8 flex-row bg-white" :class="{'fixed' : isFixed}">
       
        <div class="lg:container xl:container 2xl:container flex justify-between xs:w-full sm:w-full md:w-full">
            <router-link :to="{ name : 'Home' }" class="flex items-center" custom >
                <a href="/" @click="onGotoHome" class="flex align-middle items-center" alt="home" >
                    <h1 class="m-0"> <img src="@/assets/images/logo.svg" alt="Dish logo" title="Dish" class="pl-4 xs:pl-0 sm:pl-0" id="dish_logo"> </h1>
                </a>
            </router-link>
            <div class="flex justify-between">
                <dish-header-navigation> </dish-header-navigation> 
                <div class="flex justify-center lg:flex-col xl:flex-col 2xl:flex-col">
                    <a class="right-2 xs:visible md:visible 2xl:hidden lg:hidden xl:hidden" aria-label="Dish menu"  alt="Dish menu link" name="Dish menu" @click="setOpenMenu();">
                        <img src="@/assets/images/icon-menu.svg" alt="Dish menu icon" title="menu">
                    </a>
                </div>
            </div>
            <transition name="toLeft"> 
                <div class="fixed left-0 top-0  bg-darkgray-100 w-9/12 flex flex-col  overflow-auto h-screen xs:visible md:visible 2xl:hidden lg:hidden xl:hidden menu-mobile" v-show="isOpenMenu">
                    <div class="bg-darkgray-50 pt-5 pb-3">
                        <a href="#" class="absolute right-4" alt="menu" @click="setOpenMenu();"> 
                            <img src="@/assets/images/icon-close.png" alt="close" title="menu close" class="max-h-5">
                        </a>
                        <router-link :to="{ name : 'Home' }"  class="mx-8 mt-px-7 mb-7 md:mx-16 inline-flex"  @click="setOpenMenu();">
                            <img src="@/assets/images/logo-light.svg" alt="Dish logo    " title="Dish" width="81">
                        </router-link>
                    </div>
                    <ul class="flex flex-col justify-around items-center w-12/12 text-white pt-5">
                        <li  class="p-3 flex-auto relative font-medium hover:text-white hover:bg-red xs:hover:bg-opacity-0 text-lg rounded-xl w-10/12 my-2" v-for="( { childs, title, destination_route, icon_url, destination_type  } , index) in navbar" :key="index">
                            <a :href="destination_route" @click.prevent="setPageNumber(destination_route)" :alt="title" v-if="destination_type == 'I'" class="flex text-base w-max"  target="_self"  @click="showContent(childs, index)">
                                <img :src="icon_url" :alt="title+'-child'" class="mr-5 unset-image" v-if="icon_url">
                                <img src="@/assets/images/submenu_icon.png" class="mr-1" :alt="'navigation-child-'+title" title="nav-icon" v-else>

                                {{title}}
                                <img src="@/assets/images/icon-arrow-down-light.svg" class="ml-3" :alt="title+'-child'" v-if="childs.length != 0" >
                            </a>
                            <a :href="destination_route" target="_blank" rel="noreferrer" :alt="title"  class="flex text-base w-max"  v-if="destination_type == 'E'" @click="showContent(childs, index)"> 
                                <img :src="icon_url" :alt="title+'-child'"  v-if="icon_url">
                                <img src="@/assets/images/submenu_icon.png" class="mr-1" :alt="'navigation-child-'+title" title="nav-icon" v-else>

                                {{title}}
                                <img src="@/assets/images/icon-arrow-down-light.svg" class="ml-3" :alt="title+'-child'" v-if="childs.length != 0" :data-index="index">
                            </a>                            
                            <div class="child-container flex flex-col  z-10 nav top-10  mt-2 w-auto" v-if="childs.length != 0" v-show="childIndex == index">
                                <ul class="child px-2">
                                    <li class="flex-auto font-medium hover:text-white hover:bg-red text-base rounded-xl w-full my-4" v-for="({ title, destination_route, destination_type , icon_url, }, index) in childs" :key="index" >
                                        <a :href="destination_route" @click.prevent="setPageNumber(destination_route); setOpenMenu();" v-if="destination_type == 'I'" :alt="title"   class="flex items-center">
                                            <img :src="icon_url" class="mr-2" :alt="'navigation-child-'+title" title="nav-icon" v-if="icon_url">
                                            <img src="@/assets/images/submenu_icon.png" class="mr-1" :alt="'navigation-child-'+title" title="nav-icon" v-else>

                                            {{title}}
                                        </a>
                                        <a :href="destination_route" target="_blank" rel="noreferrer"   class="flex items-center" :alt="title"   v-if="destination_type == 'E'"> 
                                            <img :src="icon_url" class="mr-2" :alt="'navigation-child-'+title" title="nav-icon" v-if="icon_url">
                                            <img src="@/assets/images/submenu_icon.png" class="mr-1" :alt="'navigation-child-'+title" title="nav-icon" v-else>

                                            {{title}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <div v-html="tagImage"> </div>
                </div>   
            </transition>
            <transition name="fadeIn">
                <div class="w-full h-full fixed mask left-0 top-0" v-if="isOpenMenu">
                    <div class="bg-black w-full h-full" @click="setOpenMenu(); "></div>
                </div>
            </transition>
           
        </div>
    </header>
</template>

<script>
import { ref } from '@vue/reactivity'
import { mapGetters, mapState, useStore } from 'vuex'
import HeaderNavigation from './HeaderNavigation.vue'
import { onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { landing, success } from '@/pixel/pixel.js'
export default {
    computed: {
        ...mapGetters({
            navbar: 'getNavbarList',
        }),
        ...mapState({
            Navbar: state => state.Navbar,
            isOpenMenu : state => state.Navbar.isOpenMenu 
        })
    },
    components:{
        "dish-header-navigation" : HeaderNavigation,
    },
    setup() {
        const isFixed = ref(false)
        const tagImage =ref('')
        const router = useRouter()
        const route = useRoute()
        const store = useStore()
        const childIndex = ref()
        store.dispatch( 'callNavbarData' )
        const setPageNumber = ( pageNumber ) =>  {
            store.commit( 'SET_PAGE_NUMBER', pageNumber )
            router.push({ name : 'Home' })
            window.scrollTo(0, 0);
        }
        onMounted(() => {
            window.document.onscroll = () => {
                let navBar = document.getElementById('header');
                isFixed.value  = (window.scrollY > navBar.offsetTop ? true : false );   
            }
        })

        const goToSearch = () => {
            router.push({ name : 'Search' })
        }
        const showChild = ( index ) =>{
            childIndex.value = index
        }
        const showContent = (childs, index) => {
            if(childs.length == 0)
            { 
                closeMenu()
            }
            else showChild( index );
        }
        const closeMenu = () => {
            store.commit('SET_OPEN_MOBILE_MENU' , false)  
            document.documentElement.style.overflow = 'auto'
        }
        const setOpenMenu = () => {
            const isOpenMenu = store.state.Navbar.isOpenMenu
            store.commit('SET_OPEN_MOBILE_MENU', !isOpenMenu)  
            if(isOpenMenu == false){  
                document.documentElement.style.overflow = 'hidden'
            }
            else{
                document.documentElement.style.overflow = 'auto'
            }
        }


        const onGotoHome = () => {
            router.push({ name : "Home" })
        }

        const checkPixel = () =>{
            switch (route.name) {
                case 'Home':
                    landing.tag()
                    landing.floodlight()
                    tagImage.value = landing.image 
                break;
                 case 'Resumen':
                    success.tag()
                    success.floodlight()
                    tagImage.value = success.image 
                break;
            }
        }
      
        const setAttr = () => setTimeout( () =>{
            document.querySelectorAll('#StayFocusd-infobar-links a').forEach( element => { 
                element.setAttribute('href', "#")
            })
        }, 2000)


        checkPixel()
        setAttr()

        return {isFixed, childIndex, tagImage , setPageNumber,showChild, setOpenMenu, closeMenu, showContent, onGotoHome, goToSearch}
    },
}
</script>
<style scoped>
    .unset-image{
        max-width: unset;
        height: unset;
    }
    .menu-mobile{
        z-index: 10;
    }   
    .mask {
        z-index:9;
        opacity: 0.6;
    }
    .mask div {
        opacity:0.6
    }
    button i.search{
        display: block;
        width:18px;
        height: 18px;
        background-image: url(../assets/images/icon-search.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;
    }
    #header.fixed{
        position: fixed;
        z-index: 100;
        width: 100%;
        top: 0;
        left: 0;
        transition: 150ms;
    }
    @media only screen and (min-width: 1281px) and (max-width: 1366px) {
        #dish_logo {
            padding-left: 10;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1024px)  {
        #dish_logo {
            padding-left:0px;
        }
    }
    @media screen and (max-width:480px) {
        #dish_logo {
            max-height:30px;
        }
    }
</style>